import { sendEventYM } from '../../redesign/utils/common';

const inviteLink = document.querySelector('[data-invite-friend-link]');
if (inviteLink) {
  inviteLink.addEventListener('click', handleClick);
}

const shareLinks = document.querySelectorAll('[data-invite-friend-link-share]');
if (shareLinks) {
  shareLinks.forEach((el) => {
    el.addEventListener('click', handleClickShare);
  });
}

// Клуб привилегий МоиГлаза. Пригласить друга. Клик по иконкам соц.сетей
const btnYMInviteFriendShare = document.querySelectorAll('[data-btn-ym-invite-friend-share]');
if (btnYMInviteFriendShare) {
  btnYMInviteFriendShare.forEach((item) => {
    item.addEventListener('click', () => {
      sendEventYM('sent_invitation_link_friend_social', {
        sent_invitation_link_friend_social_param: item.getAttribute('data-btn-ym-invite-friend-share')
      });
    });
  });
}

const wrapper = document.querySelector('[data-invite-friend-wrapper]');

let isCodeFetching = false;
let currentCode = null;
let error = null;
let redirectStart = false;

function setControlsDisabledState(value) {
  if (!shareLinks || !shareLinks.length) {
    return;
  }

  shareLinks.forEach((el) => {
    // eslint-disable-next-line no-param-reassign
    el.disabled = value;
  });

  if (inviteLink) {
    inviteLink.disabled = value;

    if (value) {
      inviteLink.classList.add('is-disabled');
    } else {
      inviteLink.classList.remove('is-disabled');
    }
  }
}

// eslint-disable-next-line max-statements
async function handleClickShare(e) {
  if (redirectStart) {
    return;
  }

  redirectStart = true;

  setTimeout(() => {
    redirectStart = false;
  }, 5000);

  let redirectLink = null;
  const { currentTarget } = e;
  const networkType = currentTarget.dataset.inviteFriendLinkShare;

  setControlsDisabledState(true);

  await fetchCode();

  if (networkType === 'vk') {
    redirectLink = `https://vk.com/share.php?url=${currentCode}`;
  }

  if (networkType === 'tg') {
    redirectLink = `https://t.me/share/?url=${currentCode}`;
  }

  if (!error) {
    window.open(redirectLink, '_blank');
  }

  setTimeout(() => {
    setControlsDisabledState(false);
  }, 1000);
}

function setError(errors) {
  const errorsHtml = errors.map((el) => `<div>${el.message}</div>`).join('');
  error = `<div data-invite-friend-link-errors class="invite-friend-club-form__errors">${errorsHtml}</div>`;
  wrapper.insertAdjacentHTML('afterEnd', error);
}

function clearError() {
  const el = document.querySelector('[data-invite-friend-link-errors]');
  error = null;

  if (el) {
    el.remove();
  }
}

async function fetchCode(refresh) {
  isCodeFetching = true;
  clearError();

  const url = `/api/v1/referral/link/${refresh ? '?refresh=1' : ''}`;
  const params = {
    method: 'post'
  };

  let response = await fetch(url, params);

  response = await response.json();
  const { status, data, errors } = response;

  if (status === 'success') {
    currentCode = data;
  }

  if (status === 'error') {
    setError(errors);
    currentCode = null;
  }

  isCodeFetching = false;
}

// eslint-disable-next-line max-statements
function handleClick(clickEvent) {
  sendEventYM('copied_invitation_link_friend');

  if (isCodeFetching) {
    return;
  }

  if (clickEvent.target.classList.contains('is-disabled')) {
    return;
  }

  isCodeFetching = true;
  clearError();

  setControlsDisabledState(true);

  const { innerHTML } = clickEvent.target;

  const xhr = new XMLHttpRequest();

  xhr.open('POST', '/api/v1/referral/link/?refresh=1');

  xhr.send();

  // eslint-disable-next-line max-statements
  xhr.onload = () => {
    if (xhr.status !== 200) { // HTTP ошибка?
      // обработаем ошибку
      console.log(`Ошибка: ${xhr.status}`);
    }

    // получим ответ из xhr.response
    try {
      const json = JSON.parse(xhr.response);

      if (json.status === 'success') {
        clickEvent.target.setAttribute('data-promo-code', json.data);

        // eslint-disable-next-line no-shadow
        const handleClick = (e) => {
          // eslint-disable-next-line no-shadow
          const range = document.createRange();
          range.selectNodeContents(document.body);
          document.getSelection().addRange(range);

          const listener = (evt) => {
            evt.clipboardData.setData('text/plain', e.target.dataset.promoCode);
            evt.preventDefault();
          };

          document.addEventListener('copy', listener);
          document.execCommand('copy');
          document.removeEventListener('copy', listener);

          document.getSelection().removeAllRanges();
        };

        clickEvent.target.addEventListener('contextmenu', handleClick);

        setTimeout(() => {
          const event = new Event('contextmenu');
          clickEvent.target.dispatchEvent(event);
          clickEvent.target.removeEventListener('contextmenu', handleClick);
          clickEvent.target.removeAttribute('data-promo-code');

          inviteLink.innerHTML = 'Скопировано';

          setTimeout(() => {
            inviteLink.innerHTML = innerHTML;
            setControlsDisabledState(false);
          }, 1000);
        }, 0);
      }

      if (json.status === 'error') {
        setError(json.errors);

        setTimeout(() => {
          setControlsDisabledState(false);
        }, 1000);
      }
    } catch (errorScript) {
      console.error(errorScript);
    } finally {
      isCodeFetching = false;
    }
  };

  // eslint-disable-next-line no-shadow
  xhr.onerror = (errorXhr) => {
    // обработаем ошибку, не связанную с HTTP (например, нет соединения)
    console.error(errorXhr);
  };
}
